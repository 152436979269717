.carousel .control-next.control-arrow:before {
    /* border-left: 8px solid #0092b8; */
    border: solid #0092b8;
    border-width: 0 2.5px 2.5px 0;
    display: inline-block;
    padding: 6px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}

.carousel .control-prev.control-arrow:before {
    border-right: 8px solid #0092b8;
    border: solid #0092b8;
    border-width: 0 2.5px 2.5px 0;
    display: inline-block;
    padding: 6px;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
}

.carousel .control-arrow,
.carousel.carousel-slider .control-arrow {
    opacity: 1;
}
.carousel.carousel-slider .control-arrow:hover {
    background: transparent;
}

